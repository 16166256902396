import axios, {AxiosInstance, AxiosResponse} from "axios";
const baseUrl = 'https://api.benjaminvegadelpino.com/auctions-service' ;
//const baseUrl = 'http://localhost:50000' ;


export interface Auction {
    id: string;
    province: string;
    batches: number;
    deposit: string;
    minBid: string;
    bidIncrement: string;
    valuationAmount: string;
    auctionValue: string;
    claimedAmount: string;
    brand: string;
    model: string;
    plate: string;
    plateIssueDate: string;
    description: string;
    additionalInformation: string;
    charges: string;
    link: string;
    startTime: number;
    endTime: number;
    actualBid: string,

}

class ServiceAPI {
    private http: AxiosInstance;

    constructor() {
        console.log("Creating Axios")
        this.http = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-type": "application/json",
            },
        });
    }

    public fetchAuctions() : Promise<AxiosResponse<Array<Auction>>> {
        return this.http.get<Auction[]>('/auctions');
    }
}

// eslint-disable-next-line
export default new ServiceAPI();