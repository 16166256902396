import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from './state/hooks';
import './App.scss';
import { fetchAsync, selectAuctions } from './state/slices/auctionsSlice';
import { Auction } from './services/ServiceAPI';
import { AuctionDetail } from './components/AuctionDetail';


export const App = () => {
  const auctions = useAppSelector(selectAuctions);
  const [auctionsFiltered, setAuctionsFiltered] = useState<Auction[]>();
  const [latestDate, setLatestDate] = useState<number>();
  const keyword = useRef<HTMLInputElement>(null);
  const provice = useRef<HTMLSelectElement>(null);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    dispatch(fetchAsync());
  }, [])

  useEffect(()=>{
    setAuctionsFiltered(auctions);
    let sorted = auctions.map(a => a.endTime).sort();
    setLatestDate(sorted[sorted.length-1]);
  }, [auctions])

  const filtrar = () => {
    let res = [...auctions];
    if(keyword.current!.value && keyword.current!.value != '') {
      let key = keyword.current!.value.toLowerCase();
      res = res.filter(a => 
        !!a.description && a.description.toLowerCase().includes(key)
          || (!!a.additionalInformation && a.additionalInformation.toLowerCase().includes(key))
          || (!!a.brand && a.brand.toLowerCase().includes(key))
          || (!!a.model && a.model.toLowerCase().includes(key)) )
    }

    if(provice.current!.value && provice.current!.value != '') {
      let prov = provice.current!.value;
      res = res.filter(a => a.province.includes(prov));
    }

    setAuctionsFiltered(res);

  } 

  return (
    <div className="App">
      
        <div className='header'>
          <p>Buscador Portal de Subastas BOE</p>
        </div>
        <div className='search-bar'>
          <div className='group'>
            <label>Palabra clave</label>
            <input ref={keyword} className='rounded-input' type='text'/>
          </div>

          <div className='group'>
            <label>Provincia</label>
            <select ref={provice}> 
             <option value=''>Sin especificar</option>
              {auctions && auctions.length > 0 && [ ...new Set(auctions.map(a => a.province)) ].map((p, idx) => (
                <option key={idx} value={p}>{p}</option>
              ))}
            </select>
          </div>

          <button onClick={filtrar}>Buscar</button>
          
        </div>

        <div className="results">
          {auctionsFiltered && auctionsFiltered.length > 0 && auctionsFiltered.map((a, idx) =>
            
            (<AuctionDetail key={idx} auction={a}></AuctionDetail>)
            )
          }
      </div>
    </div>
  );
}

export default App;
