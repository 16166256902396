import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import ServiceAPI, { Auction } from '../../services/ServiceAPI';

export interface AuctionsState {
  auctions: Array<Auction>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AuctionsState = {
  auctions: [],
  status: 'idle',
};


export const auctionsSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.auctions = [... action.payload];
      })
      .addCase(fetchAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const fetchAsync = createAsyncThunk(
  'auctions/fetch',
  async () => {
    const response = await ServiceAPI.fetchAuctions();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

// --------------- SELECTORS ---------------------------
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuctions = (state: RootState) => state.auctions.auctions;



export default auctionsSlice.reducer;
