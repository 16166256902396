import { Auction } from '../services/ServiceAPI';
import './AuctionDetail.scss';

export const AuctionDetail = (props: {auction: Auction}) => {
    return (
        <div className="container">
              <h2> <a href={props.auction.link} target='_blank' >{props.auction.id} ➠</a> <span>({props.auction.province})</span></h2>
              <div className='information'>
                <div className='brand-plate'>
                  <p>{props.auction.brand} - {props.auction.model}</p>
                  <p>Matrícula: <strong>{props.auction.plate}</strong> del {props.auction.plateIssueDate}</p>
                </div>

                <div className='descriptions'>
                <p>{props.auction.description}</p>
                <p>{props.auction.additionalInformation}</p>
                </div>
              </div>

              <div>
                <p>Puja actual: <span className='bid'>
                  {!!props.auction.actualBid ? props.auction.actualBid : '-€'  }
                </span>
              </p>
              </div>
              
              <div className='dates'>
                <p>Incio proceso de Puja: <strong>{new Date(props.auction.startTime*1000).toLocaleString()}</strong></p>
                <p>Finalización de la puja: <strong>{new Date(props.auction.endTime*1000).toLocaleString()}</strong></p>
              </div>
              </div>
    )
}